.app-form-field-container {
  .field-disabled {
    opacity: 0.5;
    pointer-events: none;
    input[type="time"]::-webkit-calendar-picker-indicator {
        width: 24px;
        height: 24px;
        opacity: 0.5;
      }
  }
}
