@import "_variables.scss";

.recommendedCourseBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--chakra-space-12-8);
  @media (max-width: $breakpoint-mobile-desktop) {
    padding: var(--chakra-space-4);
    width: 90%;
    margin: 0px;
  }
}

.title {
  font-size: $font-size-l;
  line-height: $line-height-xlarge;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-regular;
  color: $color-default-font-color;
  margin: 0;
}

.subtitle {
  font-size: $font-size-reg;
  font-weight: $font-weight-regular;
  font-family: $font-family-regular;
  color: $color-font-card;
  margin-top: 0px;
}
