@import "_variables.scss";
.switch-styles {
  border-radius: 1.2rem;
  border: 1px solid $color-nav-selected;
  background: $color-nav-selected;
  width: 3.6rem;
  height: 2.4rem;
  flex-shrink: 0;
}
#email-alerts {
  border-radius: 1.2rem;
  border: 1px solid $color-nav-selected;
  background: $color-nav-selected;
  width: 3.6rem;
  height: 2.4rem;
  flex-shrink: 0;
}
.custom-switch input:checked + .chakra-switch__track {
  color: $color-nav-selected;
  background-color: $color-nav-selected;
  width: var(--chakra-sizes-14);
  height: var(--chakra-sizes-9);
  outline: 2px solid $color-nav-selected;
}

.custom-switch input:not(:checked) + .chakra-switch__track {
  background-color: $color-default-white;
  width: var(--chakra-sizes-14);
  height: var(--chakra-sizes-9);
  outline: 2px solid $color-nav-selected;
}

.toggle-label {
  font-size: 1.2rem;

  font-weight: 400;
  color: $color-default-black;
  line-height: 1.6rem;
}
.custom-switch input:not(:checked) + .chakra-switch__thumb {
  margin-top: -2r0em;
  height: "20px";
  width: "20px";
}
.financial-calendar {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: 400;
  line-height: var(--chakra-lineHeights-10);
  text-align: left;
}
