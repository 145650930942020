@import "_variables.scss";

.instructor-session {
    thead {
      tr {
        background-color: $color-bg-badge;
        th {
          font-size: $font-size-reg;
          font-weight: $font-weight-medium;
          text-transform: none;
          color: $color-default-black;
          padding: var(--chakra-space-6-4) var(--chakra-space-4);
        }
        .custom-table-header-data-item {
          font-size: $font-size-reg !important;
        }
        .custom-table-header-data {
          cursor: pointer;
        }
      }
    }
  
    tbody {
      .view-btn-container{
        display: flex;
        justify-content: flex-end;
        a{
          margin-right: var(--chakra-space-12-8);
        }
      }
    }
  }

.instructor-title{ 
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-l;
    line-height: $line-height-xlarge;
    margin-bottom: $gap-small;
}

.instructor-subtext{
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    line-height: $line-height-small;
}
