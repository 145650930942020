@import "_variables.scss";

.hero-banner-slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    $color-stepper-indicator,
    $color-default-font-color
  );
  height: 360px;
  width: 100%;
  color: $color-default-white;
  padding: var(--chakra-space-16);
  padding-left: var(--chakra-space-8);
  &::after {
    position: absolute;
    height: 360px;
    opacity: 0.3;
    width: 87%;
    background: url("../../../../../public/assets/images/dot-pattern.svg")
      no-repeat;
    background-size: cover;
    content: "";
    background-position: 100%;
  }
  .banner-image {
    width: 45%;
    img {
      width: 85%;
    }
  }
  .banner-text {
    z-index: 1;
    width: 65%;
    padding-left: var(--chakra-space-21-6);
    h2 {
      font-size: $font-size-xl;
      line-height: var(--chakra-space-16);
      font-weight: $font-weight-600;
    }
    p {
      width: 80%;
      font-size: $font-size-med;
      line-height: var(--chakra-space-10);
      font-weight: $font-weight-400;
      padding: var(--chakra-space-8) 0;
    }
    .banner-button {
      .custom-btn {
        color: $color-default-black;
        border-color: $color-default-black;
      }
    }
  }
}
