@import "_variables.scss";

.profile-divider {
  color: $color-gradient-light-orange;
  width: calc(100% + 64px);
  margin-left: -32px;
}

.learning-status-card-container {
  padding: 32px;
}

.user-info {
  margin-left: -32px;
}

.academy-modal-selector {
  &.app-modal-content {
    max-width: 640px;
    padding: 0px;

    .app-modal-body {
      padding: 24px;
      border-radius: 16px;
    }

    .chakra-modal__close-btn {
      top: 20px;
      right: 14px;
      svg {
        width: 14px;
        height: 14px;
      }
    }

    .app-modal-title {
      font-size: $font-size-l;
      font-weight: 600;
      padding: 0;
    }

    .chakra-modal__body {
      padding: 0;
    }

    footer {
      padding: 0;
    }
  }
}
