.add-limit-bottom-spacing {
  margin-bottom: 1.8rem;
}
.add-limit-accumulated-width {
  width: 80%;
}
.add-limit-time-field-width {
  width: 17rem;
}
.add-limit-add-new-button {
  margin-top: 3.2rem;
}
.add-limit-stack-division {
  flex-direction: row;
  width: 60%;
}
.add-limit-flex-basis {
  flex-basis: 50%;
}
.input-width-small {
  width: 25%;
}
.custom-table-data-row {
  .custom-table-padding-splitButton {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    float: right;
  }
  .custom-table-padding-appLabel {
    padding-top: var(--chakra-space-6);
    padding-bottom: var(--chakra-space-6);
    padding-left: var(--chakra-space-10);
  }
}
.app-form-field-container {
  .custom-table-header {
    .custom-table-tr-fedwire {
      .fedwire-right-custom-table-tr {
        .fedwire-custom-table-right-header {
          opacity: 0;
        }
      }
    }
  }
}
