@import "_variables.scss";

.text-closed-calendar {
  color: $color-description-txt;
  margin-left: var(--chakra-space-3);
}
.text-styling-header {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: 600;
  line-height: var(--chakra-lineHeights-10);
  text-align: left;
}

.financial-calender-business-form {
  .fin-cal-closed-section {
    background: $color-bg-badge;
    height: 48px;
    border-radius: 4px;
    .fin-cal-closed-section-img {
      margin-left: var(--chakra-lineHeights-4);
    }
  }
}
