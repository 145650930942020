@import "_variables.scss";
.app-form-field-container {
  .content-box-title {
    font-family: $font-family-regular;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
}