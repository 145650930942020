@import "_variables.scss";

.tc-entry-summary-item {
  border-radius: $border-radius-small;
  border: 1px solid $color-border;
  width: 90%;
}

.tc-summary-add-additional-text {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: $font-weight-regular;
  margin-bottom: var(--chakra-space-12-8);
}
