@import "_variables.scss";

.fin-org-corspndng-dest-net-2-3 {
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-xlarge;
}
.fin-org-corspndng-dest-frst-card-dynamc-titl {
  font-size: $font-size-2rem;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-large;
  margin-top: var(--chakra-space-4);
}
.sub-section-read-only-title {
  padding-top: var(--chakra-space-12);
  padding-bottom: var(--chakra-space-12);
  .static-text-gray {
    font-size: $font-size-reg;
  }
}
