@import "_variables.scss";

.fin-org-corresponding-summary-form {
  .app-form-container {
    margin-bottom: 3rem;
    .fin-org-summary-list-item {
      border: 1px solid $color-border;
      height: 7rem;
      align-items: center;
      border-radius: 0.8rem;
      padding: 0px 24px;
      margin-bottom: 3rem;
      h5 {
        font-size: $font-size-reg;
        font-weight: 500;
      }
    }
  }
  .fin-org-summary-add-btn {
    width: 70%;
  }
}