@import "_variables.scss";
.fedwire-box-default-width {
  .app-form-field-heading-limit-container {
    margin-bottom: var(--chakra-space-8);
    .heading-styles-add-limit-h3 {
      font-size: 2.4rem;
      margin-top: var(--chakra-space-6);
      margin-bottom: var(--chakra-space-3);
    }
  }

  .heading-styles-component-h5 {
    font-size: 1.8rem;
    margin-bottom: var(--chakra-space-12);
  }
  .app-form-field-container-sub-heading {
    margin-bottom: var(--chakra-space-3);
    .heading-styles-general-limit-h4 {
      font-size: 2rem;
      margin-top: var(--chakra-space-6);
      margin-bottom: var(--chakra-space-3);
    }
  }
  .app-form-field-container {
    .general-limit-accordian {
      margin-left: -0.8rem;
    }
    .custom-table-header {
      width: 3rem;
      border-radius: 0.8rem 0.8rem 0rem 0rem;
      .custom-table-tr-fedwire {
        height: 4.6rem;
        border-color: $color-border;
        border-top-left-radius: 0.8rem;
        .fedwire-left-custom-table-tr {
          text-transform: none;
          width: 51.5rem;
          background-color: $color-toast-info-notification;
          border-top-left-radius: 0.8rem;
        }
        .fedwire-right-custom-table-tr {
          width: 21rem;
          background-color: $color-toast-info-notification;
          border-top-right-radius: 0.8rem;
          .fedwire-right-custom-text{
            opacity: 0;
          }
        }
      }
    }
    .input-width-small {
      width: 25%;
    }
    .table {
      .custom-table-data-row {
        .custom-table-padding-appLabel {
          padding-top: var(--chakra-space-6);
          padding-bottom: var(--chakra-space-6);
          padding-left: var(--chakra-space-10);
        }
        .custom-table-padding-splitButton {
          padding-top: var(--chakra-space-6);
          padding-bottom: var(--chakra-space-6);
          float: right;
        }
      }
      .custom-table-data-last-row {
        .custom-table-padding-appLabel {
          padding-top: var(--chakra-space-6);
          padding-bottom: var(--chakra-space-6);
          padding-left: var(--chakra-space-10);
        }
        .custom-table-padding-splitButton {
          padding-top: var(--chakra-space-6);
          padding-bottom: var(--chakra-space-6);
          float: right;
        }
      }
    }
  }
}

