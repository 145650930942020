@import "_variables.scss";

.files-drag-drop-container {
  padding: 32px 16px;
  margin-top: 5px;
  background: $color-default-white;
  border: 1px dotted $color-font-card;
  border-radius: 8px;
  &:focus,
  &:hover {
    border-color: $color-link-hover-color;
  }
  .file-status-bar {
    background: $color-bg-badge;
    .file-status-divider {
      height: 14px;
      border-color: $color-divider;
      border-width: 1px;
    }
  }
}
.choose-file-text {
  padding: var(--chakra-space-6);
  margin-right: var(--chakra-space-4);
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: 700;
  line-height: $line-height-medium;
  text-align: left;
}
