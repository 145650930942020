@import "_variables.scss";

.wide-content-card {
  align-items: flex-start;
  background-color: $color-default-white;
  border: 1px solid $color-border;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  overflow: hidden;
  padding: var(--chakra-space-6-4);
  position: relative;
  margin-top: 32px;
  width: 100%;

  .div {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .frame-2 {
    display: flex;
    align-items: flex-start;
    gap: var(--chakra-space-12-8);

    .content-card {
      background-size: cover;
      border-radius: $border-radius-small;
      height: 192px;
      width: 195px;
      aspect-ratio: $aspect-ratio-image-card;
    }

    .frame-3 {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 19px;

      .frame-4 {
        display: flex;
        flex-direction: column;
        gap: $gap-small;

        .frame-5 {
          display: flex;
          gap: var(--chakra-space-12-8);
          align-items: flex-start;

          .title {
            font-size: $font-size-l;
            font-weight: $font-weight-semi-bold;
            font-family: $font-family-regular;
            color: $color-link-color;
            margin: 0;
            &:hover {
              color: $color-link-hover-color;
            }
          }
        }

        .frame-6 {
          display: flex;
          flex-direction: column;
          gap: $gap-small;

          .frame-1000005836 {
            margin-bottom: -$gap-small;
          }
        }
      }
    }
  }

  .group {
    position: relative;
    display: flex;

    .custom-rating {
      display: flex;
      gap: var(--chakra-space-2);
      flex-wrap: wrap;
    }
  }

  .ellipsis-button {
    height: 35px;
    position: absolute;
    width: 34px;
    right: var(--chakra-space-4);
    top: var(--chakra-space-4);
    cursor: pointer;

    &:hover,
    &[data-active] {
      background-color: $color-sidebar-hover-bg;

      .ellipsis-icon .path {
        fill: $color-link-hover-color;
      }
    }
  }
}

.menu-list-container.academy-menu-list {
  overflow-y: auto;
  box-shadow: $box-shadow-default; // Utilizing the predefined box shadow
  border-radius: $border-radius-large; // Assuming 20px is considered large
  padding: 0;
  .dropdown-menu-item {
    display: flex;
    align-items: center;
    border-radius: 0; // Consistency in border-radius usage
    height: 50px; // Consider a variable if this height is common
    padding: $padding-large;

    .menu-image {
      width: 24px; // Consider a variable if this size is common
      height: 24px; // Consider a variable if this size is common
      margin-right: 0; // 15px close to $padding-small, adjust if necessary
      margin-left: 0; // 20px close to var(--chakra-space-6-4), adjust if necessary
    }

    .menu-text {
      color: $color-default-font-color;
      font-family: $font-family-semi-bold;
      font-size: $font-size-reg; // Assuming 16px matches $font-size-reg
      font-weight: $font-weight-semi-bold; // Utilizing semi-bold weight for emphasis
      letter-spacing: 0.32px; // This specific value might be considered for a new variable if used frequently
      line-height: 18px; // Consider adding a variable if a common pattern emerges
    }
    &:hover {
      background: $color-sidebar-hover-bg;
    }
  }
}
