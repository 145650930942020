@import "_variables.scss";
.learning-progress-container {
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-4);
  margin-top: var(--chakra-space-12-8);

  .common-title {
    margin-bottom: var(--chakra-space-12-8);
  }

  .configuration-journey-title {
    font-family: $font-family-sfmono;
    margin-bottom: var(--chakra-space-3-2);
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    line-height: var(--chakra-space-6);
    color: $color-default-font-color !important;
  }

  .learning-path-deatils-container {
    position: relative;
    display: inline-block;
    border: 1px solid $color-link-hover-color;
    border-radius: calc(var(--chakra-space-6) + var(--chakra-space-px));
    margin-top: calc(var(--chakra-space-14) - var(--chakra-space-px));
    min-height: 200px;
    &::before {
      background: url("../../../../../public/assets/images/up-arrow.svg")
        no-repeat;
      width: var(--chakra-space-10);
      height: var(--chakra-space-10);
      top: calc(var(--chakra-space-8-8) * -1);
    }
    .learning-path-deatils-section {
      max-height: 592px;
      overflow: scroll;
      margin-bottom: var(--chakra-space-8);
      .learning-path-course-item {
        border-top: 1px solid $color-border;
        padding: 0 var(--chakra-space-13) 0 var(--chakra-space-13);
        .tag-warning {
          box-shadow: none;
        }
        .divider-container {
          min-width: 24px;
        }
      }
    }
  }
  .chakra-stepper {
    .step-title-horizontal {
      white-space: wrap;
      text-align: center;
      .chakra-step__title {
        font-size: $font-size-small !important;
      }
    }
  }
  .step-title-horizontal {
    font-weight: bold;
  }
  .carousel-container {
    .carousel-item {
      height: 110px !important;
      padding-top: var(--chakra-space-4);
      cursor: pointer;
      &[data-index="0"] {
        border: 1px dashed $color-link-hover-color;
        border-radius: var(--chakra-space-3-2);
      }
      &:has([data-status="active"]) {
        background: $color-sidebar-hover-bg;
        border-radius: var(--chakra-space-3-2);
        [data-status="active"] {
          background: $color-sidebar-hover-bg;
        }
      }
      [data-status="active"] {
        font-weight: bold;
        font-family: $font-family-bold;
      }
      .in-progress-learningpath {
        cursor: pointer;
        height: var(--chakra-space-10);
      }
      &:not(:has([data-status="active"])):hover {
        background-color: $color-bg-badge;
        border-radius: var(--chakra-space-3-2);
        hr {
          border-color: transparent;
        }
      }
    }
  }
  .completed-status-icon {
    color: $color-toast-success-highlight;
  }
  .enrolled-status-icon {
    color: $color-stepper-border;
  }
  .visible-border {
    border-color: $color-divider;
    margin-right: 1px;
  }
  .transparent-border {
    border-color: transparent;
  }
  .tag-default {
    padding: var(--chakra-space-2) var(--chakra-space-4);
  }
  .tag-success,
  .tag-warning {
    padding: var(--chakra-space-2) var(--chakra-space-8);
  }
}
